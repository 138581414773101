import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { rem } from 'polished'
import { Row, Col } from 'react-flexa'
import { motion } from 'framer-motion'
import { useAppContext } from '../../context/AppContext'
import Title from '../common/Title'
import Paragraph from '../common/Paragraph'
import DragTension from '../common/DragTension'

const Home = ({  }) => {
  const {
    image: { setImage },
    setColorMode,
  } = useAppContext()
  useEffect(() => {
    setColorMode(0)
    setImage(null)
  }, [setColorMode, setImage])

  return (
    <Container>
      <StyledRow justifyContent="flex-start">
        <TitleCol display="flex" xs={12} lg={8}>
          <TitleContainer>
            <Title>ABOUT</Title>
          </TitleContainer>
        </TitleCol>
        <BodyCol xs={12} lg={4}>
          <StyledRow flexDirection="column" justifyContent={{ xs: 'flex-start', lg: 'center' }} alignItems="center">
            <ParagraphContainer
              as={motion.div}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 1 }}
            >
              <Paragraph as="p">
                Z&P has provided quality construction services in the Greater Washington Area since 1995. The full
                service company specializes in custom kitchen and bathroom remodeling. Whether new construction or
                remodeling, projects are completed by fully licensed and bonded employees who work solely for Z&P
                Construction Inc.
              </Paragraph>
              <Paragraph>
                <a href="mailto:zandp@comcast.net">zandp@comcast.net</a>
                <br />
                <a href="tel:301-294-3331">301-294-3331</a>
                <br />
                <a href="tel:301-442-0204">301-442-0204</a>
              </Paragraph>
            </ParagraphContainer>
          </StyledRow>
        </BodyCol>
      </StyledRow>
    </Container>
  )
}

Home.propTypes = {
}

export default Home

const Container = styled.div`
  ${({ theme }) => css`
    width: 100%;
    height: 100vh;
    min-height: 100%;
    position: relative;
  `}
`

const ParagraphContainer = styled.div`
  ${({ theme }) => css`
    padding-top: ${theme.space(2)};
    position: relative;
    z-index: 10;
    ${theme.media.lg`
    padding-top: 20vh;
  `}
    > * {
      &:last-child {
        margin-top: ${theme.space(4)};
        ${theme.media.lg`
          margin-top: ${theme.space(8)};
        `}
      }
    }
  `}
`

const TitleContainer = styled.div(
  ({ theme }) => css`
    padding-top: ${rem(theme.layout.headerHeight)};
    ${theme.media.lg`
    padding: 0;
  `}

    > * {
      margin-left: ${theme.space(2)};
      ${theme.media.lg`
      margin-left: ${theme.space(11)};
    `}
    }
  `,
)

const StyledRow = styled(Row)`
  ${({ theme }) => css`
    overflow: hidden;
    height: 100%;
    padding-top: ${theme.space(6)};
    position: relative;
    ${theme.media.lg`
      padding-top: 0;
    `}
  `}
`

const TitleCol = styled(Col)`
  ${({ theme, height }) => css`
    position: relative;
    height: 33%;
    align-items: center;
    align-content: center;
    ${theme.media.lg`
      height: 100%;
    `}
  `}
`
const BodyCol = styled(TitleCol)`
  ${({ theme, height }) => css`
    height: 66%;
    ${theme.media.lg`
      height: 100%;
    `}
  `}
`
