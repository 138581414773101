import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import useTelemetry from '../../hooks/useTelemetry'

const DragTension = ({ className, children, dragFriction }) => {
  const { x, y } = useTelemetry()

  return (
    <Container
      className={className}
      style={{
        transform: `translate(${-x * dragFriction}px,${-y * dragFriction}px)`,
      }}
    >
      {children}
    </Container>
  )
}

DragTension.propTypes = {
  className: PropTypes.string,
  dragFriction: PropTypes.number,
  children: PropTypes.any,
}
DragTension.defaultProps = {
  dragFriction: 0.04,
}

export default DragTension

const Container = styled.div(
  ({ theme }) => css`
    position: relative;
    z-index: 1;
  `,
)
